import React, { useState } from 'react';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBIcon,
    MDBInputGroup,
    MDBInput
} from 'mdb-react-ui-kit';

function Dashboard() {
    const [search, setSearch] = useState('');

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h2>Dashboard</h2>
                        <MDBInputGroup className="search-input" style={{ maxWidth: '300px' }}>
                            <MDBInput
                                type="text"
                                placeholder="Search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                style={{ borderRadius: '8px' }}
                            />
                        </MDBInputGroup>
                    </div>

                    {/* Statistics Section */}
                    <MDBRow className="mb-4 g-4">
                        <MDBCol md="3">
                            <MDBCard className="stat-card h-100">
                                <MDBCardBody className="d-flex justify-content-between align-items-start">
                                    <div>
                                        <h6>Today's Orders</h6>
                                        <h3 className="mb-0">1.4K</h3>
                                    </div>
                                    <MDBIcon fas icon="box" className="stat-icon" />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBCard className="stat-card h-100">
                                <MDBCardBody className="d-flex justify-content-between align-items-start">
                                    <div>
                                        <h6>Pending Orders</h6>
                                        <h3 className="mb-0">15</h3>
                                    </div>
                                    <MDBIcon fas icon="clock" className="stat-icon" />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBCard className="stat-card h-100">
                                <MDBCardBody className="d-flex justify-content-between align-items-start">
                                    <div>
                                        <h6>Completed Orders</h6>
                                        <h3 className="mb-0">2.8K</h3>
                                    </div>
                                    <MDBIcon fas icon="check-circle" className="stat-icon" />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBCard className="stat-card h-100">
                                <MDBCardBody className="d-flex justify-content-between align-items-start">
                                    <div>
                                        <h6>Revenue</h6>
                                        <h3 className="mb-0">100K+ SAR</h3>
                                    </div>
                                    <MDBIcon fas icon="dollar-sign" className="stat-icon" />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>

                    {/* Main Content Cards */}
                    <MDBRow className="g-4">
                        <MDBCol md="6">
                            <MDBCard className="content-card h-100">
                                <MDBCardBody className="d-flex align-items-center justify-content-center">
                                    <MDBIcon fas icon="fa-regular fa-chart-simple" size="10x" />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="6">
                            <MDBCard className="content-card h-100">
                                <MDBCardBody className="d-flex align-items-center justify-content-center">
                                    <MDBIcon fas icon="fa-solid fa-arrow-trend-up" size="10x" />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="6">
                            <MDBCard className="content-card h-100">
                                <MDBCardBody className="d-flex align-items-center justify-content-center">
                                    <MDBIcon fas icon="fa-solid fa-arrow-trend-down" size="10x" />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="6">
                            <MDBCard className="content-card h-100">
                                <MDBCardBody className="d-flex align-items-center justify-content-center">
                                    <MDBIcon fas icon="fa-regular fa-chart-simple" size="10x" />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        </>
    );
}

export default Dashboard;
