import React, { useState, useEffect } from 'react';
import TopNavbar from '../../TopNavbar';
import SideNavbar from '../../SideNavbar';
import api from '../../../services';

import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBSwitch,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBInput,
    MDBIcon,
} from 'mdb-react-ui-kit';

function UserList() {
    const [viewMode, setViewMode] = useState('list');
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        async function fetchUsers() {
            try {
                const token = localStorage.getItem('token');
                const response = await api.get('/admin/users', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const filteredUsers = response.data.users.filter(user => user.user_type !== 'admin');
                setUsers(filteredUsers);
            } catch (error) {
                console.error('Error fetching users:', error.response?.data || error.message);
            }
        }
        fetchUsers();
    }, []);

    const toggleUserStatus = async (userId, currentStatus) => {
        try {
            const token = localStorage.getItem('token');
            const newStatus = currentStatus === 'Active' ? 'Inactive' : 'Active';

            const response = await api.patch(
                `/admin/users/${userId}/status`,
                { status: newStatus },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            setUsers(users.map(user =>
                user._id === userId ? { ...user, status: response.data.user.status } : user
            ));
        } catch (error) {
            console.error('Error updating user status:', error.response?.data || error.message);
            alert('Failed to update status. Please try again.');
        }
    };

    const filteredUsers = users.filter(user =>
        user.username.toLowerCase().includes(search.toLowerCase()) ||
        user.email.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4">User List</h2>

                    <MDBRow className="mb-4 d-flex align-items-center">
                        <MDBCol md="4">
                            <MDBDropdown>
                                <MDBDropdownToggle>Select Branch</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link>Branch 1</MDBDropdownItem>
                                    <MDBDropdownItem link>Branch 2</MDBDropdownItem>
                                    <MDBDropdownItem link>Branch 3</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>
                        <MDBCol md="4">
                            <MDBInput
                                type="text"
                                placeholder="Search Users"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </MDBCol>
                        <MDBCol md="4" className="d-flex justify-content-end align-items-center">
                            <MDBIcon icon="filter" className="me-2" />
                            <MDBDropdown>
                                <MDBDropdownToggle color="secondary">Filters</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link>Active</MDBDropdownItem>
                                    <MDBDropdownItem link>Inactive</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                            <span className="me-2">Sort by</span>
                            <MDBDropdown>
                                <MDBDropdownToggle>Recent</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link>Recent</MDBDropdownItem>
                                    <MDBDropdownItem link>Oldest</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>
                    </MDBRow>

                    {viewMode === 'list' && (
                        <MDBTable align="middle" responsive>
                            <MDBTableHead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Joining Date</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {filteredUsers.map((user, index) => (
                                    <tr key={user._id}>
                                        <td>{index + 1}</td>
                                        <td>{user.username}</td>
                                        <td>{user.email}</td>
                                        <td>{user.phone}</td>
                                        <td>{new Date(user.createdAt).toLocaleDateString()}</td>
                                        <td>
                                            <MDBSwitch
                                                checked={user.status === 'Active'}
                                                label={user.status}
                                                onChange={() => toggleUserStatus(user._id, user.status)}
                                            />
                                        </td>
                                        <td>
                                            <MDBBtn color="info" outline size="sm">Edit</MDBBtn>
                                            <MDBBtn color="danger" outline size="sm" className="ms-2">Delete</MDBBtn>
                                        </td>
                                    </tr>
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    )}
                </MDBContainer>
            </div>
        </>
    );
}

export default UserList;
