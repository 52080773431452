import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function SuccessModal({ show, onClose }) {
    const navigate = useNavigate();

    const handleBackToLogin = () => {
        navigate('/?login=true');
        onClose(); 
    };

    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Body className="text-center">
                <div className="mb-3">
                    <span className="display-1">✓</span>
                </div>
                <h4>Password Updated Successfully</h4>
                <Button variant="primary" onClick={handleBackToLogin} className="w-100 mt-3">
                    Back to Login
                </Button>
            </Modal.Body>
        </Modal>
    );
}

export default SuccessModal;
