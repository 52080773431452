// App.js
import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import CatalogOverview from './components/CatalogOverview';
import EditCatalog from './components/EditCatalog';
import AdminLogin from './components/Admin/Auth/AdminLogin';
import AdminDashboard from './components/Admin/AdminDashboard';
import AddCatalog from './components/AddCatalog';
import InventoryPage from './components/InventoryPage';
import Expenses from './components/Expenses';
import Items from './components/Items';
import AppsPage from './components/AppsPage';
import Reports from './components/Reports';
import ResetPassword from './components/ResetPassword';
import Dashboard from './components/Dashboard';
import Platforms from './components/Platforms';
import Orders from './components/Orders';
import Menu from './components/Menu';
import Branches from './components/Branches';
import Profile from './components/Profile';
import Setup2FA from './components/Setup2FA';
import UserList from './components/Admin/Users/UserList';

const PrivateRoute = ({ children, adminOnly }) => {
  const token = localStorage.getItem('token');
  const userType = JSON.parse(atob(token.split('.')[1])).user_type;

  // Redirect based on token type
  if (!token) return <Navigate to="/?login=true" replace />;
  if (adminOnly && userType === 'admin') return <Navigate to="/admin/dashboard" replace />;
  return children;
};

function App() {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/admin/login" element={<AdminLogin />} />

        {/* Admin Dashboard */}
        <Route
          path="/admin/dashboard"
          element={<PrivateRoute adminOnly><AdminDashboard /></PrivateRoute>}
        />
        <Route
          path="/admin/user-list"
          element={<PrivateRoute adminOnly><UserList /></PrivateRoute>}
        />

        {/* Protected Routes */}
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/platforms" element={<PrivateRoute><Platforms /></PrivateRoute>} />
        <Route path="/orders" element={<PrivateRoute><Orders /></PrivateRoute>} />
        <Route path="/catalog-overview" element={<PrivateRoute><CatalogOverview /></PrivateRoute>} />
        <Route path="/inventory" element={<PrivateRoute><InventoryPage /></PrivateRoute>} />
        <Route path="/expenses" element={<PrivateRoute><Expenses /></PrivateRoute>} />
        <Route path="/reports" element={<PrivateRoute><Reports /></PrivateRoute>} />
        <Route path="/catalog/items-list" element={<PrivateRoute><Items /></PrivateRoute>} />
        <Route path="/catalog/apps-list" element={<PrivateRoute><AppsPage /></PrivateRoute>} />
        <Route path="/edit-catalog" element={<PrivateRoute><EditCatalog /></PrivateRoute>} />
        <Route path="/add-catalog" element={<PrivateRoute><AddCatalog /></PrivateRoute>} />
        <Route path="/menu" element={<PrivateRoute><Menu /></PrivateRoute>} />
        <Route path="/branches" element={<PrivateRoute><Branches /></PrivateRoute>} />
        <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
        <Route path="/setup-2fa" element={<PrivateRoute><Setup2FA /></PrivateRoute>} />

        {/* Catch-all for undefined routes */}
        <Route path="*" element={<h1>404 - Page Not Found</h1>} />
      </Routes>
    </Router>
  );
}

export default App;
