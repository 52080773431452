import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import api from '../../services/index';
import ForgotPasswordModal from './ForgotPasswordModal';

function LoginModal({ show, onClose }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            const response = await api.post('/login', { email, password });
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user_type', response.data.user_type); // Store user_type in localStorage
            console.log("Login successful:", response.data.message);
            onClose();
            navigate('/dashboard');
        } catch (error) {
            setError(error.response?.data?.message || "An error occurred during login.");
        }
    };

    const handleForgotPasswordClick = () => {
        onClose();
        setShowForgotPassword(true);
    };

    return (
        <>
            <Modal show={show} onHide={onClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="email" className="mb-3">
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="password" className="mb-3">
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>
                        <div className="d-flex justify-content-between mb-3">
                            <a
                                href="#"
                                className="small text-muted"
                                onClick={handleForgotPasswordClick}
                            >
                                Forgot Password?
                            </a>
                        </div>
                        {error && <p className="text-danger">{error}</p>}
                        <Button variant="primary" onClick={handleLogin} className="w-100">
                            Log In
                        </Button>
                    </Form>
                    <hr />
                    <div className="text-center">
                        <span>New to LOOPS? </span>
                        <a href="/register" className="fw-bold">Create Account</a>
                    </div>
                </Modal.Body>
            </Modal>

            <ForgotPasswordModal
                show={showForgotPassword}
                onClose={() => setShowForgotPassword(false)}
            />
        </>
    );
}

export default LoginModal;
