// ResetPassword.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ResetPasswordModal from './Modals/ResetPasswordModal';

function ResetPassword() {
    const [showModal, setShowModal] = useState(true); // Initially set to true to show the modal
    const { token } = useParams(); // Get the token from the URL
    const navigate = useNavigate();

    const handleClose = () => {
        setShowModal(false);
        navigate('/login'); // Navigate back to login when the modal is closed
    };

    useEffect(() => {
        if (!token) {
            navigate('/'); // Redirect if token is missing
        }
    }, [token, navigate]);

    return (
        <ResetPasswordModal show={showModal} onClose={handleClose} token={token} />
    );
}

export default ResetPassword;
