// Home.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Card, Button } from 'react-bootstrap';
import logo from '../assets/logos/logo.png';
import LoginModal from './Modals/LoginModal';

const Home = () => {
    const [showModal, setShowModal] = useState(false);
    const location = useLocation();

    // Open the modal if "login=true" is present in the URL query parameters
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get('login') === 'true') {
            setShowModal(true);
        }
    }, [location]);

    const toggleModal = () => setShowModal(!showModal);

    return (
        <Container fluid className="p-5 d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
            <Card style={{ maxWidth: "500px", width: "100%" }}>
                <Card.Header className="d-flex justify-content-center align-items-center p-4">
                    <img src={logo} alt="logo" style={{ maxWidth: "150px", width: "100%" }} />
                </Card.Header>

                <Card.Body className="text-center">
                    <h2 className="mb-4">LOOPS Business Dashboard</h2>
                    <div className="d-grid gap-2 col-6 mx-auto mb-4">
                        <Button variant="primary" size="lg" onClick={toggleModal}>Login</Button>
                        <Link to="/register">
                            <Button variant="outline-secondary" size="lg">Register</Button>
                        </Link>
                    </div>
                </Card.Body>

                <Card.Footer className="text-center text-muted">
                    <p>
                        By continuing, you agree to our{' '}
                        <Link to="/terms" className="small text-muted">Terms & Conditions</Link>{' '}
                        and{' '}
                        <Link to="/privacy" className="small text-muted">Privacy Policy</Link>.
                    </p>
                </Card.Footer>
            </Card>

            {/* Custom Login Modal */}
            <LoginModal show={showModal} onClose={() => setShowModal(false)} />
        </Container>
    );
};

export default Home;
