import React from 'react';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBIcon,
    MDBTypography,
    // Other imports if needed
} from 'mdb-react-ui-kit';

function Menu() {


    return (
        <>
            <TopNavbar />
            <div className="d-flexContent">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area">
                    <MDBCard className='text-center' style={{ margin: 'auto', marginTop: '5rem', maxWidth: '600px' }}>
                        <MDBCardBody>
                            <MDBIcon fas icon="utensils" size='3x' className='my-3' />
                            <MDBTypography tag='h1' className='mb-3'>
                                Coming Soon
                            </MDBTypography>
                            <p>We're working hard to bring you this feature. Stay tuned!</p>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </div>

        </>
    );
}

export default Menu;
