import React, { useState } from 'react';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBTable,
    MDBTableBody,
    MDBBadge,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBInput,
    MDBIcon,
    MDBTableHead
} from 'mdb-react-ui-kit';

function Orders() {
    const [activeTab, setActiveTab] = useState('All');
    const [selectedBranchName, setSelectedBranchName] = useState('Select Branch');
    const [viewMode, setViewMode] = useState('list'); // State to manage the view mode

    // Mock data for orders
    const ordersData = [
        { id: 1, platform: "Uber Eats", orderNumber: "#45218", customer: "Criss Jordan", date: "Nov 2, 2023 9:15 PM", items: "1 x Pizza, 1 x Fajita Pizza", totalValue: "$85", vat: "16%", status: "Pending" },
        { id: 2, platform: "Uber Eats", orderNumber: "#45219", customer: "John Doe", date: "Nov 3, 2023 12:30 PM", items: "2 x Burger, 1 x Fries", totalValue: "$45", vat: "16%", status: "Completed" },
        // Add more mock orders as needed
    ];

    const handleTabClick = (tab) => setActiveTab(tab);
    const handleBranchSelect = (branchName) => setSelectedBranchName(branchName);
    const handleViewModeChange = (mode) => setViewMode(mode);

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4">All Orders</h2>

                    {/* Statistics Section */}
                    <MDBRow className="mb-4 g-4">
                        <MDBCol md="4">
                            <MDBCard className="stat-card h-100">
                                <MDBCardBody>
                                    <h6>Total Orders</h6>
                                    <h3 className="mb-0">105</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="4">
                            <MDBCard className="stat-card h-100">
                                <MDBCardBody>
                                    <h6>Order Value</h6>
                                    <h3 className="mb-0">$120k</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="4">
                            <MDBCard className="stat-card h-100">
                                <MDBCardBody>
                                    <h6>App Sales</h6>
                                    <h3 className="mb-0">$380k</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>

                    {/* Quick Actions */}
                    <MDBRow className="mb-4">
                        <h6>Quick Actions</h6>
                        <MDBCol>
                            <div className="d-flex align-items-center gap-3 quick-actions-container">
                                <MDBBtn color="primary" outline className="quick-action-btn" >Turn off Apps</MDBBtn>
                                <MDBBtn color="primary" outline className="quick-action-btn">Accept all orders</MDBBtn>
                                <MDBBtn color="primary" outline className="quick-action-btn">Reject all orders</MDBBtn>
                                <MDBBtn color="primary" outline className="quick-action-btn">Update Timings</MDBBtn>
                            </div>
                        </MDBCol>
                    </MDBRow>

                    {/* Branch Selection, Search, and View Toggle */}
                    <MDBRow className="mb-4 d-flex align-items-center">
                        <MDBCol md="4">
                            <MDBDropdown>
                                <MDBDropdownToggle>{selectedBranchName}</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link onClick={() => handleBranchSelect("Branch 1")}>Branch 1</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={() => handleBranchSelect("Branch 2")}>Branch 2</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={() => handleBranchSelect("Branch 3")}>Branch 3</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>
                        <MDBCol md="4">
                            <MDBInput type="text" placeholder="Search Order" />
                        </MDBCol>
                        <MDBCol md="4" className="d-flex justify-content-end align-items-center">
                        <MDBIcon icon="filter" className="me-2" />
                            <MDBDropdown>
                                <MDBDropdownToggle  color="secondary" >Filters</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link>By Date</MDBDropdownItem>
                                    <MDBDropdownItem link>By Day</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                            <span className="me-2">Sort by</span>
                            <MDBDropdown>
                                <MDBDropdownToggle>Recent</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link>Recent</MDBDropdownItem>
                                    <MDBDropdownItem link>Oldest</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                            <div className="ms-3">
                                <MDBBtn
                                    color="light"
                                    onClick={() => handleViewModeChange('list')}
                                    active={viewMode === 'list'}
                                >
                                    List
                                </MDBBtn>
                                <MDBBtn
                                    color="light"
                                    onClick={() => handleViewModeChange('grid')}
                                    active={viewMode === 'grid'}
                                >
                                    Grid
                                </MDBBtn>
                            </div>
                        </MDBCol>
                    </MDBRow>

                    {/* Orders Tabs */}
                    <MDBTabs className="mb-3">
                        {['All', 'Pending', 'In progress', 'Cancelled', 'Completed'].map(tab => (
                            <MDBTabsItem key={tab}>
                                <MDBTabsLink onClick={() => handleTabClick(tab)} active={activeTab === tab}>
                                    {tab}
                                </MDBTabsLink>
                            </MDBTabsItem>
                        ))}
                    </MDBTabs>

                    {/* Orders Content */}
                    {viewMode === 'grid' ? (
                        <MDBRow>
                            {ordersData.map(order => (
                                <MDBCol md="4" key={order.id}>
                                    <MDBCard className="order-card h-100">
                                        <MDBCardBody className="position-relative">
                                            <MDBBadge className="position-absolute top-0 end-0 m-2" color={order.status === "Pending" ? "warning" : "success"} pill>{order.status}</MDBBadge>
                                            <div>
                                                <h5 className="mb-1">{order.orderNumber}</h5>
                                                <small className="text-muted">{order.platform}</small>
                                            </div>
                                            <div className="text-center my-3">
                                                <h6>{order.totalValue}</h6>
                                                <small className="text-muted">VAT: {order.vat}</small>
                                            </div>
                                            <div className="text-center">{order.items}</div>
                                            <div className="d-flex justify-content-between mt-3">
                                                <MDBBtn color="danger" outline size="sm">Reject</MDBBtn>
                                                <MDBBtn color="success" outline size="sm">Accept</MDBBtn>
                                            </div>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            ))}
                        </MDBRow>
                    ) : (
                        <MDBTable align="middle" responsive>
                            <MDBTableHead>
                                <tr>
                                    <th>#</th>
                                    <th>Order Number</th>
                                    <th>Customer</th>
                                    <th>Items</th>
                                    <th>Total Value</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {ordersData.map((order) => (
                                    <tr key={order.id}>
                                        <td><input type="checkbox" /></td>
                                        <td>{order.orderNumber}</td>
                                        <td>{order.customer}</td>
                                        <td>{order.items}</td>
                                        <td>{order.totalValue}</td>
                                        <td>
                                            <MDBBadge color={order.status === "Pending" ? "warning" : "success"} pill>
                                                {order.status}
                                            </MDBBadge>
                                        </td>
                                        <td>
                                            <MDBBtn color="danger" outline size="sm" className="me-2">Reject</MDBBtn>
                                            <MDBBtn color="success" outline size="sm">Accept</MDBBtn>
                                        </td>
                                    </tr>
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    )}
                </MDBContainer>
            </div>
        </>
    );
}

export default Orders;
