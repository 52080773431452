import React, { useState } from 'react';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBRow,
    MDBCol,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBBadge,

} from 'mdb-react-ui-kit';

function Platforms() {
    const [openModal, setOpenModal] = useState(false);

    const toggleModal = () => {
        setOpenModal(!openModal);
    };

    const platformsData = [
        // Replace with API data
        { id: 1, name: 'Platform 1', logo: 'logo.png', status: 'Active' },
        { id: 2, name: 'Platform 2', logo: 'logo2.png', status: 'Inactive' },
        // ... more platforms
    ];

    return (
        <>
            <TopNavbar />
            <div className="d-flexContent">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area">
                    <h2>Platforms</h2>
                    <MDBRow className='mb-4 g-4'>
                        <MDBCol md='12'>
                            <MDBCard className='h-100'>
                                <MDBCardBody className="text-center">
                                    {platformsData.length === 0 && (
                                        <p>No Platforms Added</p>

                                    )}
                                    <MDBBtn onClick={toggleModal}>Add a platform</MDBBtn>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow>
                        <MDBCol md='12'>
                            <MDBCard className='content-card'>
                                <MDBCardBody>
                                    <MDBTable align='middle' responsive>
                                        <MDBTableHead>
                                            <tr>
                                                <th scope='col'>#</th>
                                                <th scope='col'>Platform</th>
                                                <th scope='col'>Logo</th>
                                                <th scope='col'>Status</th>
                                                <th scope='col'>Actions</th>
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {platformsData.map((platform) => (
                                                <tr key={platform.id}>
                                                    <td>{platform.id}</td>
                                                    <td>{platform.name}</td>
                                                    <td>
                                                        <img src={platform.logo} alt={platform.name} style={{ width: '45px', height: '45px' }} className='rounded-circle' />
                                                    </td>
                                                    <td>
                                                        <MDBBadge color={platform.status === 'Active' ? 'success' : 'danger'} pill>
                                                            {platform.status}
                                                        </MDBBadge>
                                                    </td>
                                                    <td>
                                                        <MDBBtn className='btn-info' size='sm'>
                                                            Edit
                                                        </MDBBtn>
                                                    </td>
                                                </tr>
                                            ))}
                                        </MDBTableBody>
                                    </MDBTable>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>

            {/* Modal for adding new platform */}
            <MDBModal open={openModal} setOpen={setOpenModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Add Platform</MDBModalTitle>
                            <MDBBtn className='btn-close' onClick={toggleModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBInput className='mb-4' type='text' label='Username' />
                            <MDBInput className='mb-4' type='password' label='Password' />
                            <MDBInput className='mb-4' type='text' label='Token ID' />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleModal}>Close</MDBBtn>
                            <MDBBtn>Submit</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>


        </>
    );
}

export default Platforms;
