import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logos/logo.png';
import api from '../services/index';
import {
    MDBNavbar,
    MDBContainer,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBIcon
} from 'mdb-react-ui-kit';

function TopNavbar() {
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
    const [username, setUsername] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            const fetchUserProfile = async () => {
                try {
                    const token = localStorage.getItem('token');
                    const response = await api.get('/profile', {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    setUsername(response.data.username || 'User');
                } catch (error) {
                    console.error("Error fetching user profile:", error);
                    localStorage.removeItem('token');
                    setIsLoggedIn(false);
                    navigate('/?login=true'); 
                }
            };

            fetchUserProfile();
        }
    }, [isLoggedIn, navigate]);

    const handleLogout = () => {
        const userType = localStorage.getItem('user_type');
        localStorage.removeItem('token');
        localStorage.removeItem('user_type');
        setIsLoggedIn(false);

        if (userType === 'admin') {
            navigate('/admin/login'); 
        } else {
            navigate('/?login=true'); 
        }
    };

    const handleLogoClick = () => {
        const userType = localStorage.getItem('user_type');
        if (userType === 'admin') {
            navigate('/admin/dashboard');
        } else {
            navigate('/dashboard');
        }
    };

    return (
        <MDBNavbar expand="lg" light bgColor="white">
            <MDBContainer fluid>
                <MDBNavbarBrand onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
                    <img
                        src={logo}
                        alt="Loops"
                        style={{ maxWidth: "150px" }}
                        className="img-fluid"
                    />
                </MDBNavbarBrand>

                <MDBNavbarNav right fullWidth={false} className="mb-2 mb-lg-0">
                    {isLoggedIn ? (
                        <MDBNavbarItem>
                            <MDBDropdown>
                                <MDBDropdownToggle tag="a" className="nav-link d-flex align-items-center">
                                    <MDBIcon fas icon="user" className="me-2" />
                                    <span>{username}</span>
                                </MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link onClick={() => navigate('/profile')}>Profile Settings</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={handleLogout}>Logout</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBNavbarItem>
                    ) : (
                        <MDBNavbarItem>
                            <MDBNavbarLink href="/?login=true">Login</MDBNavbarLink>
                        </MDBNavbarItem>
                    )}
                </MDBNavbarNav>
            </MDBContainer>
        </MDBNavbar>
    );
}

export default TopNavbar;
