import React, { useState } from 'react';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBBtn,
    MDBInput,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBIcon
} from 'mdb-react-ui-kit';

function InventoryPage() {
    const [searchQuery, setSearchQuery] = useState('');

    const inventoryData = [
        { id: 1, item: "Burger Bun", currentStock: 80, minStock: 100, orderStock: 20, price: "SAR 35/ piece" },
        { id: 2, item: "Beef patty", currentStock: 30, minStock: 100, orderStock: 70, price: "SAR 35/ piece" },
        { id: 3, item: "Cheese slice", currentStock: 100, minStock: 100, orderStock: 0, price: "SAR 35/ piece" },
        { id: 4, item: "Pepsi", currentStock: 233, minStock: 300, orderStock: 77, price: "SAR 35/ piece" },
        { id: 5, item: "Onions", currentStock: 100, minStock: 100, orderStock: 0, price: "SAR 35/ piece" },
        { id: 6, item: "Apple", currentStock: 100, minStock: 100, orderStock: 0, price: "SAR 35/ piece" },
        { id: 7, item: "Oranges", currentStock: 100, minStock: 100, orderStock: 0, price: "SAR 35/ piece" },
        { id: 8, item: "Watermelons", currentStock: 100, minStock: 100, orderStock: 0, price: "SAR 35/ piece" },
    ];

    const filteredInventory = inventoryData.filter(item =>
        item.item.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4" style={{ color: "#333" }}>Inventory</h2>

                    {/* Statistics Section */}
                    <MDBRow className="mb-4 g-4">
                        <MDBCol md="4">
                            <MDBCard className="stat-card h-100 bg-light" style={{ borderRadius: "10px" }}>
                                <MDBCardBody className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <h6 className="text-muted mb-1">Current Inventory Items</h6>
                                        <h3 className="mb-0">23,234</h3>
                                    </div>
                                    <MDBIcon icon="image" size="lg" />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="4">
                            <MDBCard className="stat-card h-100 bg-light" style={{ borderRadius: "10px" }}>
                                <MDBCardBody className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <h6 className="text-muted mb-1">Current Inventory Cost</h6>
                                        <h3 className="mb-0">23K SAR</h3>
                                    </div>
                                    <MDBIcon icon="image" size="lg" />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="4">
                            <MDBCard className="stat-card h-100 bg-light" style={{ borderRadius: "10px" }}>
                                <MDBCardBody className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <h6 className="text-muted mb-1">Out of Stock Items</h6>
                                        <h3 className="mb-0">1287</h3>
                                    </div>
                                    <MDBIcon icon="image" size="lg" />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>

                    {/* Search Bar and Add Button */}
                    <MDBRow className="mb-4 d-flex align-items-center">
                        <MDBCol md="6">
                            <MDBInput 
                                type="text" 
                                placeholder="Search Item" 
                                value={searchQuery} 
                                onChange={(e) => setSearchQuery(e.target.value)} 
                                style={{ borderRadius: "8px" }}
                            />
                        </MDBCol>
                        <MDBCol md="6" className="d-flex justify-content-end">
                            <MDBBtn color="dark" style={{ borderRadius: "8px" }}>+ Add Item</MDBBtn>
                        </MDBCol>
                    </MDBRow>

                    {/* Sort and Filter Section */}
                    <MDBRow className="mb-3 d-flex align-items-center">
                        <MDBCol md="6" className="d-flex align-items-center">
                            <MDBIcon icon="filter" className="me-2" />
                            <MDBDropdown>
                                <MDBDropdownToggle color="secondary" style={{ borderRadius: "8px" }}>Filter By</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link>Relevance</MDBDropdownItem>
                                    <MDBDropdownItem link>Price</MDBDropdownItem>
                                    <MDBDropdownItem link>Stock Level</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>
                        <MDBCol md="6" className="d-flex justify-content-end align-items-center">
                            <span className="me-2">Sort By:</span>
                            <MDBDropdown>
                                <MDBDropdownToggle  style={{ borderRadius: "8px" }}>Relevance</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link>Relevance</MDBDropdownItem>
                                    <MDBDropdownItem link>Price</MDBDropdownItem>
                                    <MDBDropdownItem link>Stock Level</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>
                    </MDBRow>

                    {/* Inventory Table */}
                    <MDBCard style={{ borderRadius: "10px" }}>
                        <MDBCardBody>
                            <MDBTable align="middle" responsive className="mb-0">
                                <MDBTableHead className="bg-light">
                                    <tr>
                                        <th>Item</th>
                                        <th>Current Stock</th>
                                        <th>Req Min Stock</th>
                                        <th>To Order Stock</th>
                                        <th>Price</th>
                                        <th>Actions</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {filteredInventory.map(item => (
                                        <tr key={item.id}>
                                            <td>{item.item}</td>
                                            <td>{item.currentStock}</td>
                                            <td>{item.minStock}</td>
                                            <td>{item.orderStock}</td>
                                            <td>{item.price}</td>
                                            <td>
                                                <MDBBtn color="secondary" outline size="sm">Update stock</MDBBtn>
                                            </td>
                                        </tr>
                                    ))}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </div>
        </>
    );
}

export default InventoryPage;
