import React, { useState } from 'react';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBInput,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
} from 'mdb-react-ui-kit';

function Expenses() {
    const [selectedApp, setSelectedApp] = useState('Uber Eats');
    const [selectedBranch, setSelectedBranch] = useState('Select Branch');
    const [searchQuery, setSearchQuery] = useState('');

    // Mock data for expenses
    const expensesData = [
        { id: "#ABC456", type: "Uber Eats", orderAmount: "$74.56", commission: "$14.40", dateTime: "23 Sep, 2023 12:30 pm" },
        { id: "#ABC789", type: "Uber Eats", orderAmount: "$96.45", commission: "$18.96", dateTime: "23 Sep, 2023 12:30 pm" },
        { id: "#ABC369", type: "Deliveroo Foodpanda", orderAmount: "$36.23", commission: "$5.60", dateTime: "23 Sep, 2023 12:30 pm" },
        // Add more mock data if needed
    ];

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4">All Expenses</h2>

                    {/* App and Branch Selection */}
                    <MDBRow className="mb-4 d-flex align-items-center">
                        <MDBCol md="6" className="d-flex gap-3">
                            <MDBDropdown>
                                <MDBDropdownToggle color="primary" style={{ borderRadius: "8px" }}>
                                    {selectedApp}
                                </MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link onClick={() => setSelectedApp('Uber Eats')}>Uber Eats</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={() => setSelectedApp('Deliveroo Foodpanda')}>Deliveroo Foodpanda</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>

                            <MDBDropdown>
                                <MDBDropdownToggle color="primary" style={{ borderRadius: "8px" }}>
                                    {selectedBranch}
                                </MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link onClick={() => setSelectedBranch('Branch 1')}>Branch 1</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={() => setSelectedBranch('Branch 2')}>Branch 2</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>
                    </MDBRow>

                    {/* Summary Cards */}
                    <MDBRow className="mb-4 g-3">
                        <MDBCol md="3">
                            <MDBCard className="h-100" style={{ borderRadius: "10px" }}>
                                <MDBCardBody>
                                    <h6>Total Orders</h6>
                                    <h3>105</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBCard className="h-100" style={{ borderRadius: "10px" }}>
                                <MDBCardBody>
                                    <h6>Completed Orders</h6>
                                    <h3>100</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBCard className="h-100" style={{ borderRadius: "10px" }}>
                                <MDBCardBody>
                                    <h6>Total Value</h6>
                                    <h3>$380k</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBCard className="h-100" style={{ borderRadius: "10px" }}>
                                <MDBCardBody>
                                    <h6>Total Commission</h6>
                                    <h3>$380k</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>

                    {/* Search Bar */}
                    <MDBRow className="mb-4">
                        <MDBCol md="4" className="ms-auto">
                            <MDBInput
                                type="text"
                                placeholder="Search Order"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                style={{ borderRadius: "8px" }}
                            />
                        </MDBCol>
                    </MDBRow>

                    {/* Orders Table */}
                    <MDBTable responsive>
                        <MDBTableHead className="bg-light">
                            <tr>
                                <th>Order ID</th>
                                <th>Type</th>
                                <th>Order Amount</th>
                                <th>Commission</th>
                                <th>Date & Time</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {expensesData
                                .filter(expense => expense.id.toLowerCase().includes(searchQuery.toLowerCase()))
                                .map((expense, index) => (
                                    <tr key={index}>
                                        <td>{expense.id}</td>
                                        <td>{expense.type}</td>
                                        <td>{expense.orderAmount}</td>
                                        <td>{expense.commission}</td>
                                        <td>{expense.dateTime}</td>
                                    </tr>
                                ))}
                        </MDBTableBody>
                    </MDBTable>
                </MDBContainer>
            </div>
        </>
    );
}

export default Expenses;
