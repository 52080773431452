import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ListGroup, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTachometerAlt,
  faReceipt,
  faFolder,
  faBoxes,
  faFileInvoiceDollar,
  faAngleDown,
  faAngleUp,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

export default function SideNavbar() {
  const location = useLocation();
  const [collapseCatalog, setCollapseCatalog] = useState(false);
  const [collapseExpenses, setCollapseExpenses] = useState(false);

  // Retrieve user type from localStorage
  const userType = localStorage.getItem('user_type');

  const isActive = (path) => location.pathname === path;

  const toggleCatalog = () => setCollapseCatalog(!collapseCatalog);
  const toggleExpenses = () => setCollapseExpenses(!collapseExpenses);

  // Determine the dashboard path based on user type
  const dashboardPath = userType === 'admin' ? '/admin/dashboard' : '/dashboard';

  return (
    <div className="position-sticky shadow-0 border sidebar">
      <ListGroup className="mx-3 mt-4">
        {/* Dashboard link with conditional path */}
        <Link to={dashboardPath} className="d-block">
          <ListGroup.Item active={isActive(dashboardPath)} className="border-0 border-bottom rounded">
            <FontAwesomeIcon icon={faTachometerAlt} className="me-3" />
            Dashboard
          </ListGroup.Item>
        </Link>

        {userType === 'admin' ? (
          // Admin-specific menu
          <>
            <Link to="/admin/user-list" className="d-block">
              <ListGroup.Item active={isActive('/admin/user-list')} className="border-0 border-bottom rounded">
                <FontAwesomeIcon icon={faUsers} className="me-3" />
                User List
              </ListGroup.Item>
            </Link>
          </>
        ) : (
          // Company-specific menu
          <>
            <Link to="/orders" className="d-block">
              <ListGroup.Item active={isActive('/orders')} className="border-0 border-bottom rounded">
                <FontAwesomeIcon icon={faReceipt} className="me-3" />
                Orders
              </ListGroup.Item>
            </Link>

            {/* Catalog Management Dropdown */}
            <ListGroup.Item
              action
              onClick={toggleCatalog}
              className="border-0 border-bottom rounded d-flex justify-content-between align-items-center"
              style={{ cursor: 'pointer' }}
            >
              <div>
                <FontAwesomeIcon icon={faFolder} className="me-3" />
                Catalog Management
              </div>
              <FontAwesomeIcon icon={collapseCatalog ? faAngleUp : faAngleDown} />
            </ListGroup.Item>

            <Collapse in={collapseCatalog}>
              <div>
                <Link to="/catalog-overview" className="d-block">
                  <ListGroup.Item
                    active={isActive('/catalog-overview')}
                    className="border-0 border-bottom rounded ps-5"
                  >
                    Catalog Overview
                  </ListGroup.Item>
                </Link>
                <Link to="/catalog/items-list" className="d-block">
                  <ListGroup.Item
                    active={isActive('/catalog/items-list')}
                    className="border-0 border-bottom rounded ps-5"
                  >
                    Items
                  </ListGroup.Item>
                </Link>
                <Link to="/catalog/apps-list" className="d-block">
                  <ListGroup.Item
                    active={isActive('/catalog/apps-list')}
                    className="border-0 border-bottom rounded ps-5"
                  >
                    Apps
                  </ListGroup.Item>
                </Link>
              </div>
            </Collapse>

            <Link to="/inventory" className="d-block">
              <ListGroup.Item active={isActive('/inventory')} className="border-0 border-bottom rounded">
                <FontAwesomeIcon icon={faBoxes} className="me-3" />
                Inventory
              </ListGroup.Item>
            </Link>

            {/* Expenses & Reports Dropdown */}
            <ListGroup.Item
              action
              onClick={toggleExpenses}
              className="border-0 border-bottom rounded d-flex justify-content-between align-items-center"
              style={{ cursor: 'pointer' }}
            >
              <div>
                <FontAwesomeIcon icon={faFileInvoiceDollar} className="me-3" />
                Expenses & Reports
              </div>
              <FontAwesomeIcon icon={collapseExpenses ? faAngleUp : faAngleDown} />
            </ListGroup.Item>

            <Collapse in={collapseExpenses}>
              <div>
                <Link to="/expenses" className="d-block">
                  <ListGroup.Item
                    active={isActive('/expenses')}
                    className="border-0 border-bottom rounded ps-5"
                  >
                    All Expenses
                  </ListGroup.Item>
                </Link>
                <Link to="/reports" className="d-block">
                  <ListGroup.Item
                    active={isActive('/reports')}
                    className="border-0 border-bottom rounded ps-5"
                  >
                    Reports
                  </ListGroup.Item>
                </Link>
              </div>
            </Collapse>
          </>
        )}
      </ListGroup>
    </div>
  );
}
