import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBTable,
    MDBTableBody,
    MDBSwitch,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBInput,
    MDBIcon,
    MDBTableHead
} from 'mdb-react-ui-kit';

function CatalogOverview() {
    const [activeTab, setActiveTab] = useState('All');
    const [viewMode, setViewMode] = useState('list');
    const [catalogsData, setCatalogsData] = useState([
        { id: 1, name: "Catalog 1", app: "Uber Eats", branches: 2, items: 24, status: true },
        { id: 2, name: "Catalog 2", app: "Uber Eats, Door Dash", branches: 3, items: 74, status: false },
        { id: 3, name: "Catalog 3", app: "Deliveroo Foodpanda", branches: 1, items: 56, status: false },
    ]);

    const [selectedBranch, setSelectedBranch] = useState('Select Branch');
    const [selectedApp, setSelectedApp] = useState('Select App');

    const navigate = useNavigate();

    const handleTabClick = (tab) => setActiveTab(tab);
    const handleViewModeChange = (mode) => setViewMode(mode);

    const toggleStatus = (id) => {
        setCatalogsData(prevData =>
            prevData.map(catalog =>
                catalog.id === id ? { ...catalog, status: !catalog.status } : catalog
            )
        );
    };

    const handleEditClick = (id) => {
        navigate('/edit-catalog', { state: { catalogId: id } });
    };

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4">Catalog Overview</h2>

                    {/* Statistics Section */}
                    <MDBRow className="mb-4 g-4">
                        <MDBCol md="4">
                            <MDBCard className="stat-card h-100">
                                <MDBCardBody>
                                    <h6>Total Catalogs</h6>
                                    <h3 className="mb-0">3</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="4">
                            <MDBCard className="stat-card h-100">
                                <MDBCardBody>
                                    <h6>Total Items</h6>
                                    <h3 className="mb-0">154</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="4">
                            <MDBCard className="stat-card h-100">
                                <MDBCardBody>
                                    <h6>Total Branches</h6>
                                    <h3 className="mb-0">6</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>

                    {/* Quick Actions */}
                    <MDBRow className="mb-4">
                        <h6>Quick Actions</h6>
                        <MDBCol>
                            <div className="d-flex align-items-center gap-2">
                                <MDBDropdown>
                                    <MDBDropdownToggle color="primary" style={{ borderRadius: "8px" }}>
                                        {selectedBranch}
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu>
                                        <MDBDropdownItem link onClick={() => setSelectedBranch('Branch 1')}>Branch 1</MDBDropdownItem>
                                        <MDBDropdownItem link onClick={() => setSelectedBranch('Branch 2')}>Branch 2</MDBDropdownItem>
                                        <MDBDropdownItem link onClick={() => setSelectedBranch('Branch 3')}>Branch 3</MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>

                                <MDBDropdown>
                                    <MDBDropdownToggle color="primary" style={{ borderRadius: "8px" }}>
                                        {selectedApp}
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu>
                                        <MDBDropdownItem link onClick={() => setSelectedApp('Uber Eats')}>Uber Eats</MDBDropdownItem>
                                        <MDBDropdownItem link onClick={() => setSelectedApp('Door Dash')}>Door Dash</MDBDropdownItem>
                                        <MDBDropdownItem link onClick={() => setSelectedApp('Deliveroo')}>Deliveroo</MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>

                                <MDBBtn
                                        color="primary"
                                        className="quick-action-btn"
                                        onClick={() => navigate('/add-catalog')}
                                    >
                                        Add New Catalog
                                    </MDBBtn>
                            </div>
                        </MDBCol>
                    </MDBRow>

                    {/* Search and View Toggle */}
                    <MDBRow className="mb-4 d-flex align-items-center">
                        <MDBCol md="6">
                            <MDBInput type="text" placeholder="Search Catalogs" />
                        </MDBCol>
                        <MDBCol md="6" className="d-flex justify-content-end align-items-center">
                            <span className="me-2">Sort by</span>
                            <MDBDropdown>
                                <MDBDropdownToggle color="primary">Recent</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link>Recent</MDBDropdownItem>
                                    <MDBDropdownItem link>Oldest</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                            <div className="ms-3">
                                <MDBBtn
                                    color="light"
                                    onClick={() => handleViewModeChange('list')}
                                    active={viewMode === 'list'}
                                >
                                    List
                                </MDBBtn>
                                <MDBBtn
                                    color="light"
                                    onClick={() => handleViewModeChange('grid')}
                                    active={viewMode === 'grid'}
                                >
                                    Grid
                                </MDBBtn>
                            </div>
                        </MDBCol>
                    </MDBRow>

                    {/* Catalog Content */}
                    {viewMode === 'grid' ? (
                        <MDBRow>
                            {catalogsData.map(catalog => (
                                <MDBCol md="4" key={catalog.id}>
                                    <MDBCard className="order-card h-100">
                                        <MDBCardBody className="position-relative">
                                            <MDBSwitch checked={catalog.status} onChange={() => toggleStatus(catalog.id)} label={catalog.status ? "Active" : "Not Active"} className="position-absolute top-0 end-0 m-2" />
                                            <div>
                                                <h5 className="mb-1">{catalog.name}</h5>
                                                <small className="text-muted">{catalog.app}</small>
                                            </div>
                                            <div className="text-center my-3">
                                                <h6>{catalog.items} Items</h6>
                                                <small className="text-muted">{catalog.branches} Branches</small>
                                            </div>
                                            <div className="d-flex justify-content-between mt-3">
                                                <MDBBtn color="link" size="sm" onClick={() => handleEditClick(catalog.id)}>Edit</MDBBtn>
                                                <MDBBtn color="link" size="sm">Delete</MDBBtn>
                                            </div>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            ))}
                        </MDBRow>
                    ) : (
                        <MDBTable align="middle" responsive>
                            <MDBTableHead>
                                <tr>
                                    <th>#</th>
                                    <th>Catalog Name</th>
                                    <th>App</th>
                                    <th>Branches</th>
                                    <th>Items</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {catalogsData.map((catalog, index) => (
                                    <tr key={catalog.id}>
                                        <td><input type="checkbox" /></td>
                                        <td>{catalog.name}</td>
                                        <td>{catalog.app}</td>
                                        <td>{catalog.branches}</td>
                                        <td>{catalog.items}</td>
                                        <td>
                                            <MDBSwitch checked={catalog.status} onChange={() => toggleStatus(catalog.id)} label={catalog.status ? "Active" : "Not Active"} />
                                        </td>
                                        <td>
                                            <MDBBtn color="secondry" outline size="sm" className="me-2" onClick={() => handleEditClick(catalog.id)}>Edit</MDBBtn>
                                            <MDBBtn color="danger" outline size="sm">Delete</MDBBtn>
                                        </td>
                                        {/* <td>
                                            <MDBBtn color="secondary" onClick={() => handleEditClick(catalog.id)}>Edit</MDBBtn>
                                            <MDBBtn color="danger">Delete</MDBBtn>
                                        </td> */}
                                    </tr>
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    )}
                </MDBContainer>
            </div>
        </>
    );
}

export default CatalogOverview;
