import React, { useState } from 'react';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBBtn,
    MDBInput,
    MDBCard,
    MDBCardBody
} from 'mdb-react-ui-kit';

function Items() {
    const [searchQuery, setSearchQuery] = useState('');

    const itemsData = [
        { id: 1, name: "Pizza", description: "A round base of dough topped with tomato sauce, cheese, and various ingredients such as meats, vegetables, and herbs", category: "Appetisers", price: "SAR 12.00" },
        { id: 2, name: "Chicken Biryani", description: "Chicken Biryani is a flavorful and aromatic South Asian dish made with basmati rice, chicken, and a blend of aromatic spices.", category: "Main Course", price: "SAR 11.00" },
        { id: 3, name: "Mutton Biryani", description: "Chicken Biryani is a flavorful and aromatic South Asian dish made with basmati rice, chicken, and a blend of aromatic spices.", category: "Main Course", price: "SAR 11.00" },
        { id: 4, name: "Pepsi", description: "Pepsi is known for its sweet and refreshing taste.", category: "Beverages", price: "SAR 11.00" },
        { id: 5, name: "Mirinda", description: "It is known for its fruity and citrus flavors, with variations such as orange, lemon, and grapefruit.", category: "Beverages", price: "SAR 11.00" },
        { id: 6, name: "Scrambled Eggs", description: "Scrambled eggs are a dish made by beating eggs and cooking them in a pan, typically with butter or oil.", category: "Breakfast", price: "SAR 11.00" }
    ];

    const filteredItems = itemsData.filter(item =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4" style={{ color: "#333" }}>All Items</h2>

                    {/* Search Bar and Add Button */}
                    <MDBRow className="mb-3">
                        <MDBCol md="6">
                            <MDBInput 
                                type="text" 
                                placeholder="Search Item" 
                                value={searchQuery} 
                                onChange={(e) => setSearchQuery(e.target.value)} 
                                style={{ borderRadius: "8px" }}
                            />
                        </MDBCol>
                        <MDBCol md="6" className="d-flex justify-content-end">
                            <MDBBtn color="primary" style={{ borderRadius: "8px" }}>+ Add Item</MDBBtn>
                        </MDBCol>
                    </MDBRow>

                    {/* Items Table */}
                    <MDBCard style={{ borderRadius: "10px" }}>
                        <MDBCardBody>
                            <MDBTable align="middle" responsive className="mb-0">
                                <MDBTableHead className="bg-light">
                                    <tr>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Category</th>
                                        <th>Price</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {filteredItems.map(item => (
                                        <tr key={item.id}>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        src="https://via.placeholder.com/40"
                                                        alt="Item"
                                                        className="rounded me-3"
                                                        style={{ width: "40px", height: "40px" }}
                                                    />
                                                    <div>{item.name}</div>
                                                </div>
                                            </td>
                                            <td>{item.description}</td>
                                            <td>{item.category}</td>
                                            <td>{item.price}</td>
                                        </tr>
                                    ))}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </div>
        </>
    );
}

export default Items;
