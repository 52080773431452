import React, { useState } from 'react';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBBtn,
    MDBInput,
    MDBCard,
    MDBCardBody
} from 'mdb-react-ui-kit';

function AppsPage() {
    const [searchQuery, setSearchQuery] = useState('');

    const appsData = [
        { id: 1, appName: "Door Dash", catalogName: "Breakfast Catalog", commissionRate: "5%" },
        { id: 2, appName: "Zomato", catalogName: "Catalog 2", commissionRate: "6%" },
        { id: 3, appName: "Deliveroo", catalogName: "Breakfast Catalog", commissionRate: "11%" },
        { id: 4, appName: "Hunger Station", catalogName: "Catalog 3", commissionRate: "12%" },
    ];

    const filteredApps = appsData.filter(app =>
        app.appName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4" style={{ color: "#333" }}>All Apps</h2>

                    {/* Search Bar and Add Button */}
                    <MDBRow className="mb-3">
                        <MDBCol md="6">
                            <MDBInput 
                                type="text" 
                                placeholder="Search Item" 
                                value={searchQuery} 
                                onChange={(e) => setSearchQuery(e.target.value)} 
                                style={{ borderRadius: "8px" }}
                            />
                        </MDBCol>
                        <MDBCol md="6" className="d-flex justify-content-end">
                            <MDBBtn color="primary" style={{ borderRadius: "8px" }}>+ Add App</MDBBtn>
                        </MDBCol>
                    </MDBRow>

                    {/* Apps Table */}
                    <MDBCard style={{ borderRadius: "10px" }}>
                        <MDBCardBody>
                            <MDBTable align="middle" responsive className="mb-0">
                                <MDBTableHead className="bg-light">
                                    <tr>
                                        <th>App Name</th>
                                        <th>Catalog Name</th>
                                        <th>Commission Rate</th>
                                        <th>Actions</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {filteredApps.map(app => (
                                        <tr key={app.id}>
                                            <td>{app.appName}</td>
                                            <td>{app.catalogName}</td>
                                            <td>{app.commissionRate}</td>
                                            <td>
                                                <MDBBtn color="secondary" outline size="sm" style={{ marginRight: "8px" }}>Edit</MDBBtn>
                                                <MDBBtn color="danger" outline size="sm">Delete</MDBBtn>
                                            </td>
                                        </tr>
                                    ))}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </div>
        </>
    );
}

export default AppsPage;
