// Profile.js
import React, { useState, useEffect } from 'react';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import api from '../services/index';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBInput,
    MDBBtn,
    MDBCardImage,
} from 'mdb-react-ui-kit';

function Profile() {
    const [userData, setUserData] = useState({
        username: '',
        email: '',
        phone: ''
    });
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [userMessage, setUserMessage] = useState('');

    // 2FA state
    const [qrCode, setQrCode] = useState('');
    const [secret, setSecret] = useState('');
    const [token, setToken] = useState('');
    const [setupCompleted, setSetupCompleted] = useState(false);
    const [twoFAError, setTwoFAError] = useState('');
    const [twoFAMessage, setTwoFAMessage] = useState('');
    const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);

    // Fetch user profile on mount
    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await api.get('/profile', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setUserData(response.data);
                setTwoFactorEnabled(response.data.twoFactorEnabled); // Check 2FA status
            } catch (error) {
                console.error("Error fetching profile:", error);
            }
        };
        fetchProfile();
    }, []);

    // Fetch QR code for 2FA setup if 2FA is not enabled
    useEffect(() => {
        if (!twoFactorEnabled) { // Only fetch QR if 2FA is not enabled
            const setup2FA = async () => {
                try {
                    const response = await api.post('/2fa/setup', {}, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    });
                    setQrCode(response.data.qrCodeDataURL);
                    setSecret(response.data.secret);
                    setTwoFactorEnabled(response.data.twoFactorEnabled);
                } catch (error) {
                    setTwoFAError("Failed to generate 2FA QR Code.");
                    console.error("Error setting up 2FA:", error);
                }
            };
            setup2FA();
        }
    }, [twoFactorEnabled]);

    // Handle update user details
    const handleUserDetailsUpdate = async () => {
        try {
            const response = await api.put('/profile', userData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setUserMessage(response.data.message || 'Profile updated successfully');
        } catch (error) {
            console.error("Error updating profile:", error);
            setUserMessage(error.response?.data?.message || "An error occurred.");
        }
    };

    // Handle password update
    const handlePasswordUpdate = async () => {
        if (newPassword !== confirmPassword) {
            setError("New passwords do not match.");
            return;
        }
        try {
            const response = await api.put('/update-password', {
                currentPassword: oldPassword,
                newPassword: newPassword
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setMessage(response.data.message || 'Password updated successfully');
            setError('');
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
        } catch (error) {
            console.error("Error updating password:", error);
            setError(error.response?.data?.message || "An error occurred.");
            setMessage('');
        }
    };

    // Handle 2FA verification
    const handleVerify2FA = async () => {
        try {
            const response = await api.post('/2fa/verify', { token }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setTwoFAMessage(response.data.message);
            setSetupCompleted(true);
            setTwoFactorEnabled(response.data.twoFactorEnabled);
            setTwoFAError('');
        } catch (error) {
            setTwoFAError("Invalid 2FA token.");
            setTwoFAMessage('');
            console.error("Error verifying 2FA:", error);
        }
    };

    return (
        <>
            <TopNavbar />
            <div className="d-flexContent">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area">
                    <h2>Profile</h2>

                    <MDBRow>
                        {/* User Details Section */}
                        <MDBCol md='6'>
                            <MDBCard className='mb-4'>
                                <MDBCardHeader><h4>User Details</h4></MDBCardHeader>
                                <MDBCardBody>
                                    <MDBInput
                                        label="Username"
                                        type="text"
                                        className="mb-3"
                                        value={userData.username}
                                        onChange={(e) => setUserData({ ...userData, username: e.target.value })}
                                    />
                                    <MDBInput
                                        label="Email"
                                        type="email"
                                        className="mb-3"
                                        value={userData.email}
                                        onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                                    />
                                    <MDBInput
                                        label="Phone"
                                        type="tel"
                                        className="mb-3"
                                        value={userData.phone}
                                        onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
                                    />
                                    {userMessage && <p className="text-success">{userMessage}</p>}
                                    <MDBBtn onClick={handleUserDetailsUpdate}>Update Profile</MDBBtn>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>

                        {/* Update Password Section */}
                        <MDBCol md='6'>
                            <MDBCard className='mb-4'>
                                <MDBCardHeader><h4>Update Password</h4></MDBCardHeader>
                                <MDBCardBody>
                                    <MDBInput
                                        label="Current Password"
                                        type="password"
                                        className="mb-3"
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                    />
                                    <MDBInput
                                        label="New Password"
                                        type="password"
                                        className="mb-3"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                    <MDBInput
                                        label="Confirm New Password"
                                        type="password"
                                        className="mb-3"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                    {error && <p className="text-danger">{error}</p>}
                                    {message && <p className="text-success">{message}</p>}
                                    <MDBBtn onClick={handlePasswordUpdate}>Update Password</MDBBtn>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>

                        {/* Two-Factor Authentication Section */}
                        <MDBCol md='6'>
                            <MDBCard>
                                <MDBCardHeader><h4>Two-Factor Authentication</h4></MDBCardHeader>
                                <MDBCardBody>
                                    {twoFAError && <p className="text-danger">{twoFAError}</p>}
                                    {twoFAMessage && <p className="text-success">{twoFAMessage}</p>}
                                    
                                    {twoFactorEnabled ? (
                                        <p className="text-success">Two-Factor Authentication is enabled.</p>
                                    ) : (
                                        <>
                                            <div className="mb-4">
                                                <h5>Step 1: Setup 2FA</h5>
                                                {qrCode && (
                                                    <>
                                                        <p>Scan the QR code with your authenticator app:</p>
                                                        <MDBCardImage src={qrCode} alt="QR Code" className="img-fluid mb-3" />
                                                        <p><strong>Secret:</strong> {secret}</p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="mb-4">
                                                <h5>Step 2: Verify 2FA</h5>
                                                <MDBInput
                                                    label="Enter 2FA Code"
                                                    type="text"
                                                    value={token}
                                                    onChange={(e) => setToken(e.target.value)}
                                                    wrapperClass="mb-4"
                                                />
                                                <MDBBtn onClick={handleVerify2FA}>Verify</MDBBtn>
                                            </div>
                                        </>
                                    )}
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        </>
    );
}

export default Profile;
