import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import api from '../../services/index';
import ResetPasswordModal from './ResetPasswordModal';

function ForgotPasswordModal({ show, onClose }) {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showResetPassword, setShowResetPassword] = useState(false);

    const handleForgotPassword = async () => {
        try {
            await api.post('/forgot-password', { email });
            setError('');
            setSuccessMessage("A reset link has been sent to your email address.");
        } catch (error) {
            setError(error.response?.data?.message || 'An error occurred.');
        }
    };

    const handleResetClick = () => {
        setShowResetPassword(true);
    };

    return (
        <>
            <Modal show={show} onHide={onClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Forgot Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {successMessage ? (
                        <div className="text-center">
                            <p className="text-success">{successMessage}</p>
                            <Button variant="primary" onClick={handleResetClick} className="w-100">
                                Reset Password
                            </Button>
                        </div>
                    ) : (
                        <Form>
                            <Form.Group controlId="email" className="mb-3">
                                <Form.Control
                                    type="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Form.Group>
                            {error && <p className="text-danger mt-3">{error}</p>}
                            <Button variant="primary" onClick={handleForgotPassword} className="w-100">
                                Send Reset Link
                            </Button>
                        </Form>
                    )}
                </Modal.Body>
            </Modal>

            <ResetPasswordModal
                show={showResetPassword}
                onClose={() => setShowResetPassword(false)}
            />
        </>
    );
}

export default ForgotPasswordModal;
