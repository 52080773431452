import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import api from '../../services/index';
import SuccessModal from './SuccessModal';

function ResetPasswordModal({ show, onClose, token }) {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);

    const handleResetPassword = async () => {
        if (newPassword !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }
        try {
            await api.post(`/reset-password/${token}`, { newPassword });
            setShowSuccess(true);
            setError('');
        } catch (error) {
            setError(error.response?.data?.message || 'An error occurred.');
        }
    };

    const handleCloseSuccessModal = () => {
        setShowSuccess(false);
        onClose();
    };

    return (
        <>
            <Modal show={show && !showSuccess} onHide={onClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="newPassword" className="mb-3">
                            <Form.Control
                                type="password"
                                placeholder="Enter New Password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="confirmPassword" className="mb-3">
                            <Form.Control
                                type="password"
                                placeholder="Re-enter New Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </Form.Group>
                        {error && <p className="text-danger mt-3">{error}</p>}
                        <Button variant="primary" onClick={handleResetPassword} className="w-100">
                            Update
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Success Modal */}
            <SuccessModal show={showSuccess} onClose={handleCloseSuccessModal} />
        </>
    );
}

export default ResetPasswordModal;
